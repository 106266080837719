// Import React Library
import React from 'react';

// Import Icons
import GroupIcon from '@mui/icons-material/Group';
import BarChartIcon from '@mui/icons-material/BarChart';

// About Us component
const AboutUs = () => {
    return (
        <div id='about-us' className='about-us'>
            <h2>Sobre Nosotros</h2>
            <span></span>

            <div className='about-us-container'>
                <div className='about-us-text'>

                    <GroupIcon className='about-us-icon'/>
                    <h3>¿Quiénes somos?</h3>
                    <p>
                        Desde 1998, nos dedicamos a la fabricación y comercialización de baldosas de hormigón vibrado, sumando constantemente nuevos y diferentes productos a nuestro catálogo.
                    </p>

                    <BarChartIcon className='about-us-icon'/>
                    <h3>¿A qué apuntamos?</h3>
                    <p>
                        Ser una solución al trabajar con nosotros, siendo fieles a las fechas de entrega y convirtiéndonos en una preocupación menos en su proyecto. Apuntamos a un producto de calidad que perdure, ofreciendo una garantía seria y un servicio postventa. 
                        Satisfacemos las modas y demandas cambiantes en la construcción.
                    </p>
                </div>
            </div>
        </div>
    );
};

// Export About Us component
export default AboutUs;