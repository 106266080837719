// Import React Library
import { useState } from 'react';

// Import Bootstrap Modal
import Modal from 'react-bootstrap/Modal';

// Import Bootstrap Card
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

// Import Icons
import CategoryIcon from '@mui/icons-material/Category';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import SquareFootIcon from '@mui/icons-material/SquareFoot';

// Prodcut Card Component
function ProductCard({cardTitle , cardDescription, cardImage, productSize, productColor}) {

  const values = [true, 'sm-down', 'md-down', 'lg-down', 'xl-down', 'xxl-down'];
    const [fullscreen, setFullscreen] = useState(true);
    const [show, setShow] = useState(false);

    function handleShow(breakpoint) {
        setFullscreen(breakpoint);
        setShow(true);
    }

  return (
    <>
      <Card style={{ width: '18rem' }} className='card-product col-md-3 col-sm-6 col-12 mb-3'>
        <Card.Img className='card-image' variant="top" src={cardImage} />
        <Card.Body>
          <Card.Title className='card-title'>{cardTitle}</Card.Title>
          <Card.Text>
              {cardDescription}
          </Card.Text>

          <Button key={0} className='card-button' onClick={() => handleShow(true)}>
                Ver Producto
            </Button>
        </Card.Body>
      </Card>

      <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
            <Modal.Title className='modal-title-premoldeados-del-este'>{cardTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-body-premoldeados-del-este'>
          <img src={cardImage} alt='Premoldeados del este' className='img-premoldeados-del-este'/>

          <div className='premoldeados-del-este'>
            <p className='premoldeados-de-este-title'>
              <CategoryIcon className='premoldeados-del-este-icon'/> &nbsp; {cardTitle}
            </p>
            <br/>
            <p>
              <ColorLensIcon className='premoldeados-del-este-icon'/> &nbsp; {productColor}
            </p>
            <br/>
            <p>
              <SquareFootIcon className='premoldeados-del-este-icon'/> &nbsp; {productSize}
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

// Export Product Card Component
export default ProductCard;