// Import React Library
import React, {useState} from 'react';

// Import Components
import ProductsNavbar from '../components/ProductsNavbar';
import ProductsCover from '../components/ProductsCover';
import WhatsappButton from '../components/WhatsappButton';
import ProductCard from '../components/ProductCard';
import Footer from '../components/Footer';

// Import Baldosas Images
import adoquinGrandeRecto from '../assets/images/baldosas/adoquin-grande-recto.jpg';
import adoquinSeisPanes from '../assets/images/baldosas/adoquin-seis-panes.jpg';
import piedraLaja from '../assets/images/baldosas/piedra-laja.jpg';
import lajaEspanola from '../assets/images/baldosas/piedra-laja-espanola.jpg';
import adoquinRomano from '../assets/images/baldosas/adoquin-romano.jpg';
import adoquinGrandeCurvo from '../assets/images/baldosas/adoquin-grande-curvo.jpg';
import podoctatilBtn from '../assets/images/baldosas/podotactil-btn.png';
import podoctatilBarra from '../assets/images/baldosas/podotactil-barras.png';

// Import Baldosones Lisos Images
import baldosonesLisos from '../assets/images/baldosones-lisos/baldoson-liso.jpg';

// Import Camineros Lisos Images
import camineroLiso from '../assets/images/camineros/caminero-liso.jpg';
import camineroSimilMadera from '../assets/images/camineros/caminero-madera.png';

// Import Zocalos Images
import zocalo40x20 from '../assets/images/zocalos/zocalo-40x20.jpg';
import zocalo20x12 from '../assets/images/zocalos/zocalo-20x12.jpg';

// Import Piscina Images
import borde from '../assets/images/piscina/borde.jpg';
import paloma from '../assets/images/piscina/paloma.jpg';
import deck from '../assets/images/piscina/deck.jpg';
import loseta from '../assets/images/piscina/loseta.jpg';

// Products Component
const Products = () => {

    return (
        <div>
            <ProductsNavbar />
            <ProductsCover />
            <div className='our-products-row container'>
                <h2>Baldosas</h2>
                <span></span>

                <div className='product-cards-container row'>
                    <ProductCard 
                        cardTitle='Adoquin Grande Recto'
                        cardDescription='Adoquin recto grande. 40 x 40 x 2cm (aprox). Color Gris.'
                        cardImage={adoquinGrandeRecto}
                        productSize='40 x 40 x 2cm (aprox).'
                        productColor='Gris'
                    />

                    <ProductCard 
                        cardTitle='Adoquin seis panes'
                        cardDescription='Adoquin seis panes. 40 x 40 x 2cm (aprox). Color Gris.'
                        cardImage={adoquinSeisPanes}
                        productSize='40 x 40 x 2cm (aprox).'
                        productColor='Gris'
                    />

                    <ProductCard 
                        cardTitle='Imitación piedra laja'
                        cardDescription='Imitación piedra laja. 40 x 40 x 2cm (aprox). Color Gris.'
                        cardImage={piedraLaja}
                        productSize='40 x 40 x 2cm (aprox).'
                        productColor='Gris'
                    />

                    <ProductCard 
                        cardTitle='Laja española'
                        cardDescription='Laja española. 40 x 40 x 2cm (aprox). Color Gris.'
                        cardImage={lajaEspanola}
                        productSize='40 x 40 x 2cm (aprox).'
                        productColor='Gris'
                    />

                    <ProductCard 
                        cardTitle='Adoquin romano'
                        cardDescription='Adoquin romano. 40 x 40 x 2cm (aprox). Color Gris.'
                        cardImage={adoquinRomano}
                        productSize='40 x 40 x 2cm (aprox).'
                        productColor='Gris'
                    />

                    <ProductCard 
                        cardTitle='Adoquin curvo grande'
                        cardDescription='Adoquin curvo grande. 40 x 40 x 2cm (aprox). Color Gris.'
                        cardImage={adoquinGrandeCurvo}
                        productSize='40 x 40 x 2cm (aprox).'
                        productColor='Gris'
                    />

                    <ProductCard 
                        cardTitle='Podoctatil Barra'
                        cardDescription='Podoctatil Barra. 40 x 40 x 2cm (aprox). Color Amarillo o natural.'
                        cardImage={podoctatilBarra}
                        productSize='40 x 40 x 2cm (aprox).'
                        productColor='Gris'
                    />

                    <ProductCard 
                        cardTitle='Podoctatil Botones'
                        cardDescription='Podoctatil Botones. 40 x 40 x 2cm (aprox). Color Amarillo o natural.'
                        cardImage={podoctatilBtn}
                        productSize='40 x 40 x 2cm (aprox).'
                        productColor='Amarillo o natural'
                    />
                </div>
            </div>

            <div className='our-products-row container'>
                <h2>Baldosones Lisos (Pisos técnicos)</h2>
                <span></span>

                <div className='product-cards-container row'>
                    <ProductCard 
                        cardTitle='Baldosones lisos'
                        cardDescription='Baldosones lisos. 50 x 50 x 3cm. Con o sin malla de hierro. Color Natural.'
                        cardImage={baldosonesLisos}
                        productSize='50 x 50 x 3cm.'
                        productColor='Natural'
                    />

                    <ProductCard 
                        cardTitle='Baldosones lisos'
                        cardDescription='Baldosones lisos. 60 x 60 x 3.5cm. Con o sin malla de hierro. Color Natural.'
                        cardImage={baldosonesLisos}
                        productSize='60 x 60 x 3.5cm.'
                        productColor='Natural'
                    />
                </div>
            </div>

            <div className='our-products-row container'>
                <h2>Camineros</h2>
                <span></span>

                <div className='product-cards-container row'>
                    <ProductCard 
                        cardTitle='Caminero liso'
                        cardDescription='Caminero liso. 100 x 50 x 5cm. Color Natural.'
                        cardImage={camineroLiso}
                        productSize='100 x 50 x 5cm.'
                        productColor='Natural'
                    />

                    <ProductCard 
                        cardTitle='Caminero liso'
                        cardDescription='Caminero liso. 70 x 30 x 4cm. Color Natural.'
                        cardImage={camineroLiso}
                        productSize='70 x 30 x 4cm.'
                        productColor='Natural'
                    />

                    <ProductCard 
                        cardTitle='Caminero simil madera'
                        cardDescription='Imitación madera. 100 x 26 x 6cm. Color Natural.'
                        cardImage={camineroSimilMadera}
                        productSize='100 x 26 x 6cm.'
                        productColor='Natural'
                    />
                </div>
            </div>

            <div className='our-products-row container'>
                <h2>Zocalos y revestimientos</h2>
                <span></span>

                <div className='product-cards-container row'>
                    <ProductCard 
                        cardTitle='Zocalos y revestimientos'
                        cardDescription='40 x 20 x 2cm. Color Gris.'
                        cardImage={zocalo40x20}
                        productSize='40 x 20 x 2cm.'
                        productColor='Gris'
                    />

                    <ProductCard 
                        cardTitle='Zocalos y revestimientos'
                        cardDescription='20 x 12 x 2cm. Color Gris.'
                        cardImage={zocalo20x12}
                        productSize='20 x 12 x 2cm.'
                        productColor='Gris'
                    />
                </div>
            </div>

            <div className='our-products-row container'>
                <h2>Piscinas</h2>
                <span></span>

                <div className='product-cards-container row'>
                    <ProductCard 
                        cardTitle='Borde'
                        cardDescription='Borde. 50 x 50 x 2cm. Color Crema o Natural.'
                        cardImage={borde}
                        productSize='50 x 50 x 2cm.'
                        productColor='Crema o natural'
                    />

                    <ProductCard 
                        cardTitle='Borde pecho paloma'
                        cardDescription='Borde pecho paloma. 50 x 40 x 2.5cm. Color Crema o Natural.'
                        cardImage={paloma}
                        productSize='50 x 40 x 2cm.'
                        productColor='Crema o natural'
                    />

                    <ProductCard 
                        cardTitle='Loseta'
                        cardDescription='Loseta. 50 x 50 x 3cm. Color Crema o Natural.'
                        cardImage={loseta}
                        productSize='50 x 50 x 3cm.'
                        productColor='Crema o natural'
                    />

                    <ProductCard 
                        cardTitle='Deck travertino'
                        cardDescription='Deck travertino. 50 x 50 x 2.5cm. Color Crema o Natural.'
                        cardImage={deck}
                        productSize='50 x 50 x 2.5cm.'
                        productColor='Crema o natural'
                    />
                </div>
            </div>
            <WhatsappButton />
            <Footer />
        </div>
    );
};

// Export Products Component
export default Products;