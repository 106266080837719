// Import react library
import React from 'react';

// Import react DOM
import ReactDOM from 'react-dom/client';

// Import components
import App from './App';
import Products from './pages/Products';

// Import Routing System
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

// Router Component
const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement : ''
  },
  {
    path: '/products',
    element: <Products />,
    errorElement : ''
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router}/>
  </React.StrictMode>
);
