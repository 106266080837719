// Import Styles
import './sass/index.scss';

// Import Tailwind CSS
import './styles/tailwind.css';

// Import Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';

// Import Components
import Navbar from './components/Navbar';
import Cover from './components/Cover';
import AboutUs from './components/AboutUs';
import WhatsappButton from './components/WhatsappButton';
import Contact from './components/Contact';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
      <Navbar />
      <Cover />
      <AboutUs />
      <Contact />
      <Footer />
      <WhatsappButton />
    </div>
  );
}

export default App;
