// Import React Library
import React, { useEffect } from 'react';

// Import UseState Hook
import { useState } from 'react';

// Import Icons
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import GroupIcon from '@mui/icons-material/Group';
import EmailIcon from '@mui/icons-material/Email';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';

// Import Images
import logoPreMoldeadosDelEste from '../assets/images/pmde-logo.png';

// Import React Router Link
import { Link } from 'react-router-dom';

// Navbar Component
const Navbar = ({products}) => {
     // Mobile Button
     const [navbarButtonStatus , setNavbarButtonStatus] = useState(false);
     const [openMenu , setOpenMenu] = useState(false);
     
     // Resize screen event
     window.addEventListener('resize', () =>{
         if(window.innerWidth > 1024){
             setNavbarButtonStatus(false);
             setOpenMenu(false);
         }else{
            setNavbarButtonStatus(true);
         }
     });

    useEffect(() =>{
        if(window.innerWidth > 1400){
            setNavbarButtonStatus(false);
        }else{
            setNavbarButtonStatus(true);
        }
    }, []);

    return (
        <>
            <nav className='desktop-nav'>
                
                <img src={logoPreMoldeadosDelEste} alt='Premoldeados del este logo'/>

                { !navbarButtonStatus ? <ul>
                                            <li>
                                                <Link to='/products'>Productos</Link>
                                            </li>
                                            <li>
                                                <a href='#about-us'>Nosotros</a>
                                            </li>
                                            <li>
                                                <a href='#contact-form'>Contacto</a>
                                            </li>
                                        </ul> : <MenuIcon className='menu-icon-btn' onClick={() => setOpenMenu(true)}/>}
                
            </nav>
            
            {!openMenu ? '' : 
                <div className='mobile-menu'>
                    <ul>
                        <li>
                            <Link  to='/products'>Productos <LocalGroceryStoreIcon /></Link>
                        </li>
                        <li>
                            <a href='#about-us'>Nosotros <GroupIcon /></a>
                        </li>
                        <li>
                            <a href='#contact-form'>Contacto <EmailIcon /></a>
                        </li>
                    </ul>

                    <CloseIcon className='menu-icon-btn-mobile' onClick={() => setOpenMenu(false)}/>

                    <div className='mobile-menu-footer'>
                        <p>
                            Premoldeados del Este Ⓡ - Todos los derechos reservados - 2024.
                        </p>
                    </div>
                </div>
            }
        </>
    );
};

// Export Navbar Component
export default Navbar;