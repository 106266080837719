// Import React Library
import React from 'react';

// Cover Component
const ProductsCover = () => {

    return (
        <div className='products-cover-section'>
            <h1>Nuestros Productos</h1>

            <h2>Baldosas | Camineros | Zocalos | Revestimientos | Piscinas.</h2>
        </div>
    );
};

// Export Cover Component
export default ProductsCover;