// Import React Library
import React,  { useRef, useState }  from 'react';

// Import TextFields
import TextField from '@mui/material/TextField';

// Import Email.js Library
import emailjs from '@emailjs/browser';

// Import Alerts
import Alert from '@mui/material/Alert';


// Contact Component
const Contact = () => {

    // Use form reference
    const form = useRef();

    // Input values
    const [userName , setUserName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [formMessage, setFormMessage] = useState('');
    const [formSubject, setFormSubject] = useState('');

    const [successMessage , setSuccessMessage] = useState(false);
    const [errorMessage , setErrorMessage] = useState(false);
    const [alertMessage , setAlertMessage] = useState('');

    // Send email function
    const sendContactEmail = (e) =>{
        // Prevent default action
        e.preventDefault();

        if(userName === '' || userEmail === '' || formMessage === '' || formSubject === ''){
            setAlertMessage('Debe completar todos los campos del formulario');
            setErrorMessage(true);
            setSuccessMessage(false);

            setTimeout(() =>{
                setErrorMessage(false);
                setSuccessMessage(false);
            }, 3000)
        }else{
            emailjs
            .sendForm('service_s0i04zf', 'template_9ixzd9r', form.current, {
                publicKey: '2CojZ9ZI-xwtnIYkG'
            })
            .then(
                () => {
                    // Show success alert
                    setAlertMessage('¡Muchas gracias, su mensaje ha sido enviado con éxito!');
                    setErrorMessage(false);
                    setSuccessMessage(true);

                    // Clean form
                    setUserEmail('');
                    setUserName('');
                    setFormMessage('');
                    setFormSubject('');

                    // Hide Success Alert
                    setTimeout(() =>{
                        setErrorMessage(false);
                        setSuccessMessage(false);
                    }, 3000)
                },
                (error) => {
                    // Show error message
                    setAlertMessage(error.text)
                    setErrorMessage(true);
                    setSuccessMessage(false);
                },
            );
        }
    }

    return (
        <div id="contact-form" className='contact-form'>
            <h2>CONTACTO</h2>
            <span></span>

            <form ref={form} onSubmit={sendContactEmail} className='contact-form'>
                { successMessage ? 
                    <Alert variant="outlined" severity="success" className='form-alert'>
                        {alertMessage}.
                    </Alert> : ''
                }

                { errorMessage ? 
                    <Alert variant="outlined" severity="error" className='form-alert'>
                        {alertMessage}.
                    </Alert> : ''
                }

                <TextField
                    className='input-text'
                    id="standard-multiline-flexible user-name"
                    label="Nombre"
                    multiline
                    maxRows={4}
                    variant="standard"
                    name="user_name"
                    onChange={(e) => setUserName(e.target.value)}
                    value={userName}
                />

                <TextField
                    className='input-text'
                    id="standard-multiline-flexible user-email"
                    label="Email"
                    multiline
                    maxRows={4}
                    variant="standard"
                    name="user_email"
                    onChange={(e) => setUserEmail(e.target.value)}
                    value={userEmail}
                />

                <TextField
                    className='input-text'
                    id="standard-multiline-flexible form-subject"
                    label="Asunto"
                    multiline
                    maxRows={4}
                    variant="standard"
                    name='user_subject'
                    onChange={(e) => setFormSubject(e.target.value)}
                    value={formSubject}
                />

                <TextField
                    className='input-text text-area'
                    id="standard-multiline-flexible form-message"
                    label="Mensaje"
                    multiline
                    maxRows={10}
                    variant="standard"
                    name="message"
                    onChange={(e) => setFormMessage(e.target.value)}
                    value={formMessage}
                />

                <button class="group relative inline-flex h-12 items-center justify-center overflow-hidden rounded-md bg-neutral-950 px-6 font-medium text-neutral-200 duration-500">
                    <div class="uppercase">
                        ENVIAR
                    </div>
                </button>
            </form>
        </div>
    );
};

// Export Contact Component
export default Contact;