// Import React Library
import React from 'react';

// Import Links From React Router Dom
import { Link } from 'react-router-dom';

// Cover Component
const Cover = () => {

    // Current Year
    const year = new Date().getFullYear();

    return (
        <div className='cover-section'>
            <h1>{year - 1998} Años de calidad</h1>

            <h2>Baldosas | Camineros | Zocalos | Revestimientos | Piscinas.</h2>

            <Link to='/products'>
                <button>
                    Ver Productos
                </button>
            </Link>
        </div>
    );
};

// Export Cover Component
export default Cover;